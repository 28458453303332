import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default class ResourcesPage extends Component {
  render() {
    return (
      <div></div>
    )
  }
}
